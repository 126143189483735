import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import LeftArrow from './../images/left-arrow.svg'
import RightArrow from './../images/right-arrow.svg'
import Interior from './../images/interior.jpg'
import InteriorMobile from './../images/interior-one-mobile.jpg'
import Bathroom from './../images/bathroom.jpg'
import BathroomMobile from './../images/interior-two-mobile.jpg'


export const Slider = () => {

    const [index, setIndex] = useState(0);

    const prevClick = () => {
        if(index === 0)
            return setIndex(1)
        
        setIndex(0)
    }

    const nextClick = () => {
        if(index === 1)
            return setIndex(0)

        setIndex(1)
    }

    const leftIcon = <span className="arrows left-arrow"><img src={LeftArrow} alt="left-arrow"></img></span>
    const rightIcon = <span className="arrows right-arrow"><img src={RightArrow} alt="right-arrow"></img></span>

    return (
        <>
            <Carousel interval={null} id="interior-slider" activeIndex={index} controls={false} indicators={false}>
                <Carousel.Item>
                    <img src={Interior} alt="Interior" width="100%" className="desktop-ou"></img>
                    <img src={InteriorMobile} alt="Interior" width="100%" className="mobile-ou"></img>
                </Carousel.Item>

                <Carousel.Item>
                    <img src={Bathroom} alt="Bathroom" width="100%" className="desktop-ou"></img>
                    <img src={BathroomMobile} alt="Interior" width="100%" className="mobile-ou"></img>
                </Carousel.Item>
                
            </Carousel>

            <div className="carousel-button-wrapper">
                <button onClick={prevClick} className="carousel-button">{leftIcon}</button>

                    <div className="carousel-tracker">
                        <p> { index === 0 ? "1" : "2" } / 2 </p>
                    </div>
                    
                <button onClick={nextClick} className="carousel-button">{rightIcon}</button>
            </div>
        </>
    )


}