import React from 'react' 

export const Switch = (props) => {

    const { interiorClick, exteriorClick, active } = props

    return (
        <div className="switch-wrapper-ou">
            <button className={ active === true ? "switch-btn-ou active" : "switch-btn-ou" } onClick={exteriorClick}>Exterior</button>
            <button className={ active === false ? "switch-btn-ou active" : "switch-btn-ou" } onClick={interiorClick}>Interior</button>
            <span className={ active === true ? "green-bar-ou exterior" : "green-bar-ou interior"}></span>
        </div>
    )

}