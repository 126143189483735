import React from 'react'
import { Col } from 'react-bootstrap'
import { Slider } from './../components/slider'

export const Interior = () => {

    return(
        <>
        <Col lg={5}>
             <div className="list-of-information">
                 <div className="individual-item">
                 <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="12" cy="11.5238" rx="10" ry="9.52381" fill="#82C275"/>
                    <path d="M11.296 17V8.2L8.88 9.72V7.4L11.456 5.8H13.36V17H11.296Z" fill="white"/>
                 </svg>
                     <p>Nest smart thermostat</p>
                 </div>
                 <div className="individual-item">
                 <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="12" cy="11.5238" rx="10" ry="9.52381" fill="#82C275"/>
                    <path d="M7.816 17V15.384C7.816 14.36 8.06133 13.56 8.552 12.984C8.95733 12.504 9.8 11.944 11.08 11.304C11.1547 11.272 11.2613 11.2187 11.4 11.144C11.8267 10.9413 12.1307 10.7867 12.312 10.68C12.504 10.5627 12.7173 10.4133 12.952 10.232C13.1867 10.0507 13.3467 9.85867 13.432 9.656C13.5173 9.44267 13.56 9.19733 13.56 8.92C13.56 8.504 13.3947 8.16267 13.064 7.896C12.7333 7.61867 12.2907 7.48 11.736 7.48C10.5733 7.48 9.91733 8.20533 9.768 9.656L7.72 9.384C7.79467 8.15733 8.18933 7.21333 8.904 6.552C9.62933 5.89067 10.6107 5.56 11.848 5.56C12.936 5.56 13.832 5.87467 14.536 6.504C15.2507 7.12267 15.608 7.976 15.608 9.064C15.608 9.52267 15.528 9.944 15.368 10.328C15.208 10.7013 14.9733 11.0373 14.664 11.336C14.3653 11.624 14.0613 11.8693 13.752 12.072C13.4533 12.264 13.0907 12.4667 12.664 12.68C11.8427 13.096 11.2933 13.384 11.016 13.544C10.7493 13.704 10.552 13.8747 10.424 14.056C10.2747 14.28 10.2 14.5573 10.2 14.888H15.688V17H7.816Z" fill="white"/>
                 </svg>
                    <p>Quartz countertops</p>
                 </div>
                 <div className="individual-item">
                 <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="12" cy="11.5238" rx="10" ry="9.52381" fill="#82C275"/>
                    <path d="M7.48 13.336L9.576 12.888C9.78933 14.5093 10.5413 15.32 11.832 15.32C12.4613 15.32 12.952 15.176 13.304 14.888C13.656 14.6 13.832 14.1947 13.832 13.672C13.832 12.5733 13.1333 12.024 11.736 12.024H11V10.328H11.656C12.1573 10.328 12.5573 10.2107 12.856 9.976C13.1653 9.74133 13.32 9.4 13.32 8.952C13.32 8.49333 13.176 8.136 12.888 7.88C12.6107 7.61333 12.2213 7.48 11.72 7.48C10.7493 7.48 10.1787 8.04533 10.008 9.176L8.04 8.808C8.136 7.80533 8.52 7.016 9.192 6.44C9.87467 5.85333 10.744 5.56 11.8 5.56C12.8133 5.56 13.6507 5.84267 14.312 6.408C14.984 6.96267 15.32 7.70933 15.32 8.648C15.32 9.32 15.1653 9.83733 14.856 10.2C14.5467 10.5627 14.2213 10.7973 13.88 10.904V10.952C14.328 11.048 14.7653 11.336 15.192 11.816C15.6293 12.2853 15.848 12.952 15.848 13.816C15.848 14.7973 15.496 15.6133 14.792 16.264C14.088 16.9147 13.1067 17.24 11.848 17.24C10.632 17.24 9.63467 16.9147 8.856 16.264C8.088 15.6027 7.62933 14.6267 7.48 13.336Z" fill="white"/>
                 </svg>
                    <p>Kitchen island with pot and pan drawers</p>
                 </div>
                 <div className="individual-item">
                 <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="12" cy="11.5238" rx="10" ry="9.52381" fill="#82C275"/>
                    <path d="M7.32 14.856V12.84L11.576 5.8H14.856V12.856H16.168V14.856H14.856V17H12.824V14.856H7.32ZM9.496 12.856H12.824V7.384H12.792L9.496 12.856Z" fill="white"/>
                 </svg>
                    <p>Premium bathroom finishes</p>
                 </div>
             </div>

        </Col>
             
        <Col lg={7}>
             <Slider/>
        </Col>
     </>
    )

}