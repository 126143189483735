import React, { useState, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Exterior } from './exterior'
import { Interior } from './interior'
import { Switch } from './../components/switch'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import useDimensions from 'react-cool-dimensions'

export const Layout = () => {

    /* True is for Exterier Tab */
    const [option, setOption] = useState(true)
    const nodeRef = useRef(null);

    /* Ref to container of everything, grabbing the height and Set a min-height 
       so when we transition between both options, the content below it doesn't move and down  */
       const { observe, width } = useDimensions({});

       const aspectRatio = 1.77;
       const calculatedHeight = ((width / aspectRatio) - 50)

    return (
        <Container fluid ref={observe} style={{ minHeight: `${calculatedHeight}px`}}>
            <Row>
                <Col lg={5}>
                    <h3>Optional upgrades</h3>
                    <Switch
                        interiorClick={() => setOption(false)}
                        exteriorClick={() => setOption(true)}
                        active={option}
                    />
                </Col>
            </Row>

            <SwitchTransition>
                <CSSTransition
                    key={option}
                    timeout={450}
                    classNames="fade-ou"
                    nodeRef={nodeRef}
                >

                    <Row ref={nodeRef}>
                        { option === true ? <Exterior/> : <Interior/> }
                    </Row>

                </CSSTransition>
            </SwitchTransition>
        </Container>
            
    )

}