import './styles.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Layout } from './layouts/layout'

function App() {
  return (
    <div className="App">
      <Layout/>
    </div>
  );
}

export default App;
